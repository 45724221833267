import http from "/http-common"; ///axios
class AdminService {
	getUsers(page, size, sortBy, sortDesc, search) {
		return http.get(
			`/gestionnaire/utilisateur?page=${page}&size=${size}&sort=${this.parseSort(sortBy, sortDesc)}&search=${encodeURI(
				search
			)}`
		);
	}
	searchUsers(page, size, classes, etablissement, enabled, roles, anneeScolaire, sortColumn, sortDesc) {
		return http.get(`/gestionnaire/utilisateur/search?page=${page - 1}&size=${size}&sort=${this.parseSort(sortColumn, sortDesc)}&anneeScolaire=${anneeScolaire != null ? anneeScolaire : ''}&etablissementIds=${encodeURI(etablissement.join(','))}&classes=${encodeURI(classes.join(','))}&active=${encodeURI(enabled !== null ? enabled : '')}&roles=${roles.join(',')}`)
	}

	parseSort(sortColumn, sortDesc) {
		if(sortColumn.length > 0 && sortColumn[0]) {
			if(sortDesc.length > 0) {
				return `${sortColumn[0]},${sortDesc[0] ? 'ASC' : 'DESC'}`
			}
			return sortColumn
		}
		return ''
	}

	getAllUsersDataBiblio(data) {
		return http.post(`/gestionnaire/utilisateur/bibliotheques`, data, {responseType: 'blob'});
	}
	/**
	 * Call the api to generate an export for the editor catalog
	 * @param {string} editor the concerned editor
	 * @returns the axios response from the request with the generated blob
	 */
	exportCatalogEditeur(editor) {
		return http.get(`/gestionnaire/editor/export?editor=${editor}`, {responseType: 'blob'})
	}
	updateUser(data) {
    const user = JSON.parse(JSON.stringify(data))
    if(!Array.isArray(user.classes)) {
      user.classes = [user.classes]
    }
		return http.put(`/gestionnaire/utilisateur/${data.identifiant}`, user);
	}
	createUser(data) {
		return http.post("/gestionnaire/utilisateur", data);
	}
	deleteUser(data) {
		return http.delete(`/gestionnaire/utilisateur/${data.identifiant}`);
	}
	countUserForClean(schoolYear, status, roles) {
		return http.get(`/gestionnaire/utilisateur/clean?schoolYear=${schoolYear}&status=${status != null ? status : ""}&roles=${roles.join(',')}`)
	}
	cleanUsers(schoolYear, status, roles) {
		return http.delete(`/gestionnaire/utilisateur/clean?schoolYear=${schoolYear}&status=${status != null ? status : ""}&roles=${roles.join(',')}`)
	}
	getUser(data) {
		return http.get(`/gestionnaire/utilisateur/${data}`, data);
	}

	importFromCSV(data, id) {
		return http.post(process.env.VUE_APP_BASE_URL + `batch/job/importAccountViaCSV/${id}`, data, {
			headers: {
				"Content-Type": "multipart/form-data",
			}
		});
	}

	searchBooks(
		page,
		size = 10,
		sortBy = "",
		sortDesc = false,
		search = "",
		editeur = ""
	) {
		return http.get(
			`/ouvrage?page=${page}&size=${size}&sortBy=${sortBy}&sortDesc=${sortDesc}&search=${encodeURI(
				search
			)}&editeur=${editeur}`
		);
	}
	searchEtabBooks(
		idEtablissement,
		idUtilisateur ,
	) {
		return http.get(
			`/gestionnaire/catalogue/${idEtablissement}/${idUtilisateur}`
		);
	}
	addBook(identifiant, codeOffre, etablissement) {
		return http.put(`gestionnaire/utilisateur/${etablissement}/${identifiant}/${codeOffre}`);
	}
	addBooks(data) {
		return http.put(`gestionnaire/utilisateur/ajoutOuvrages`, data);
	}
	deleteBook(identifiant, codeOffre) {
		return http.delete(`gestionnaire/utilisateur/${identifiant}/${codeOffre}`);
	}
	deleteBooks(data) {
		return http.put(`/gestionnaire/utilisateur/suppressionOuvrages`, data);
	}
	archivageComptes(data) {
		return http.put(`/gestionnaire/utilisateur/archivageComptes`, data);
	}
	suppressionComptes(data) {
		return http.put(`/gestionnaire/utilisateur/suppressionComptes`, data);
	}
	enableDisableBook(identifiant, codeOffre) {
		return http.put(`gestionnaire/utilisateur/ouvrage/${identifiant}/${codeOffre}`);
	}
	editWebCommandeId(identifiant, codeOffre, webCommandeId) {
		return http.post(`gestionnaire/utilisateur/ouvrage/${identifiant}/${codeOffre}`, webCommandeId);
	}
	getEtablissement(page, size, sortBy, sortDesc, search, isTison) {
		return http.get(
			`/gestionnaire/etablissements?page=${page}&size=${size}&isTison=${isTison}&sortBy=${sortBy}&sortDesc=${sortDesc}&search=${encodeURI(
				search
			)}`,
			{}
		);
	}
	/**
	 * Method used to retrieve all etablissement ids.
	 * Used to initialize select in some features
	 * @returns a list of all etablissement ids
	 */
	getEtablissementIds() {
		return http.get(
			`/gestionnaire/etablissements/ids`,
			{}
		);
	}
	exportEtablissements(isTison) {
		return http.get(`/gestionnaire/etablissements/export?isTison=${isTison}`, {responseType: 'blob'})
	}
	getEtablissementByID(Id) {
		return http.get(`/gestionnaire/etablissement/${Id}`);
	}
	getEtablissementByUai(uais) {
		return http.get(`/gestionnaire/etablissement/uai?uai=${uais.join(',')}`)
	}
	getClasseByID(id) {
		return http.post(`/gestionnaire/rechercherDevisEtablissement/${id}`);
	}

	getClasseByIDbyYear(id, annee) {
		return http.post(`/gestionnaire/rechercherDevisEtablissement/${id}?annee=${annee}`);
	}
	getClassesByEtablissementIds(ids) {
		return http.get(`gestionnaire/ent/classes/etablissement?idsEtablissments=${ids.join(',')}`)
	}
	getClasseEntByEtablissementId(id) {
		return http.get(`/gestionnaire/ent/etablissement/${id}/classes`);
	}
	updateNiveauClassesEnt(classes) {
		return http.put(`/gestionnaire/ent/etablissement/classes`, classes)
	}
	createEntClasses(classes) {
		return http.post(`/gestionnaire/ent/etablissement/classes`, classes)
	}
	getHistorique(page, size, sortBy, sortDesc, search, startDate, endDate) {
		return http.get(
			`/gestionnaire/historique?page=${page}&size=${size}&sortBy=${sortBy}&sortDesc=${sortDesc}&search=${encodeURI(
				search
			)}&startDate=${startDate}&endDate=${endDate}`
		);
	}
	getClassesByEtablissements(etablissements) {
		return http.get('/gestionnaire/ent/etablissement/classes?ids=' + etablissements.join(','))
	}
	getStock(page, size, sortBy, sortDesc, search) {
		return http.get(
			`/gestionnaire/stocks?page=${page}&size=${size}&sortBy=${sortBy}&sortDesc=${sortDesc}&search=${encodeURI(
				search
			)}`
		);
	}
	exportStocks() {
		return http.get('/gestionnaire/stocks/export', {responseType: 'blob'})
	}
	mapEtablissement(etablissements) {
		return http.post('/gestionnaire/ent/etablissement', etablissements)
	}
	putStock(idEtablissement, ean, data) {
		return http.put(`/gestionnaire/stocks/${idEtablissement}/${ean}`, data);
	}
	deleteStock(idEtablissement, niveaux) {
		return http.post(`/gestionnaire/stocks/${idEtablissement}`, niveaux);
	}
	getRules() {
		return http.get(`/gestionnaire/commande/rules`);
	}
	putRules(id, data) {
		return http.put(`/gestionnaire/commande/rules/${id}`, data);
	}
	getQrCode(id) {
		return http.get(`/gestionnaire/qrcode/${id}`);
	}
	exportQrCode(idEtab) {
		return http.post(`/gestionnaire/etablissement/${idEtab}/print`, {responseType: 'blob'});
	}
	exportQrCodeUtilisateurs(data) {
		return http.post(`/gestionnaire/utilisateur/print`, data);
	}
}

export default new AdminService();
